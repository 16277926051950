@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  font-family: Poppins, Roboto, Rubik, Noto Kufi Arabic, Noto Sans JP,
    sans-serif;
}

/* .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
} */

/* :where(.css-dev-only-do-not-override-gj3u7q).ant-table-wrapper */
.ant-table-tbody>tr>td {
  position: initial !important;
}

/* .ant-table-container {
  border-left: 6px solid #7158c1 !important;
} */

::-webkit-scrollbar {
  width: 7px !important;
  height: 14px !important;
}

::-webkit-scrollbar-track {
  background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
  height: 5px;
  width: 5px;
  background-color: #7158c1;
  scrollbar-width: thin;
}

.react-tiny-popover-container {
  z-index: 9;
}

.ant-table-wrapper table tr td.ant-table-selection-column {
  position: sticky !important;
  left: 0px !important;
  z-index: 1 !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  position: sticky !important;
  left: 0px !important;
  z-index: 3 !important;
}

/* .ant-table-selection-column {
  background: #fff;
} */

.ant-table-row .tableOutside {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  position: absolute !important;
  left: -50px;
  width: 50px;
  height: 50px;
}

.inputMask {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  outline: none;
}

.inputMask:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

/* .ant-spin-dot-item {
  background-color: #fff !important;
} */

.ant-message-custom-content {
  display: flex !important;
  align-items: center !important;
}

.tox-statusbar {
  display: none !important;
}
.btn-disabled:disabled {
  background-color: #c0bebe !important;
}

.light-tab > .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(102, 0, 204) !important;
}

.dark-tab > .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}


.dark-drop-down  .ant-dropdown-menu {
  background-color: #30324e!important;
  color: #fff!important;

}

.dark-drop-down  .ant-dropdown-menu-title-content{
  color: #fff!important;
}

.dark-drop-down .ant-dropdown-menu-item-disabled .ant-dropdown-menu-title-content {
  color: rgb(255 255 255 / 25%) !important;
}


.dark-drop-down {
  background-color: #30324e!important;
  background: #30324e!important;
  color: #fff!important;
}

.dark-drop-down .ant-select-item-option-content {
  color: #fff !important;
}

.dark-drop-down svg {
  color: #fff !important;
}

.dark-drop-down .ant-select-item-group {
  color: rgb(215 215 215 / 45%) !important;
}

.ant-tabs-tab-active {
  color: rgb(102, 0, 204)!important;
}

.dark-drop-down .ant-picker-panel-container {
  background-color: #30324e !important;
  color: #fff !important;
}

.dark-drop-down .ant-picker-header {
  color: #fff !important;
}

.dark-drop-down button {
  color: #fff !important;
}

.dark-drop-down .ant-picker-today-btn {
  color: #fff !important;
}

.dark-drop-down .ant-picker-body .ant-picker-content thead tr th {
  color: #fff !important;
}

.dark-drop-down .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-end {
  color: #fff !important;
}

.dark-drop-down .ant-picker-body .ant-picker-content tbody tr  .ant-picker-cell-in-view {
  color: #fff !important;
}

.dark-calendar .ant-picker-panel-container{
  background-color: #30324e;
  color: #fff;
}

.dark-calendar .ant-picker-header{ 
  color: #fff;
}

.dark-calendar .ant-picker-header button,  .dark-calendar .ant-picker-content th, .dark-calendar .ant-picker-cell-in-view{
  color: #fff !important;
}

.dark-calendar .ant-picker-cell-in-range {
 color: #30324e !important; 
}

th.ant-table-cell {
  white-space: nowrap;
}

.maxIndex {
  z-index: 99999999 !important;
}

.ant-modal-confirm-btns {
  display: flex;
  justify-content: space-between;
}